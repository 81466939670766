






































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
// @ts-ignore
import List from 'list.js/src/index';

// Make globally available as List()
// @ts-ignore
window.List = List;

@Component
export default class Modals extends Vue {

}
