










































































































































































































































































































































































































































import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
// @ts-ignore
import flatPickr from 'vue-flatpickr-component';
// @ts-ignore
import vueMask from 'vue-jquery-mask';
// @ts-ignore
import { quillEditor } from 'vue-quill-editor';
// @ts-ignore
import vue2Dropzone from 'vue2-dropzone';

@Component({
  components: {
    flatPickr,
    vueMask,
    quillEditor,
    vueDropzone: vue2Dropzone,
  },
})

export default class Forms extends Vue {
  date: Date = new Date();
  now: moment.Moment = moment();
  maskDate: string = this.now.format('l');
  maskCurrency: number = 10.25;
  content: string = 'Hello there';
}
