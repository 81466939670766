

























































































































import { Component, Vue } from 'vue-property-decorator';
// eslint-disable-next-line import/extensions
import Logo from '@/assets/icons/bloq.svg?inline';

@Component({
  components: {
    Logo,
  },
})

export default class Navbar extends Vue {

}
