






























































































































































































































































































































































































































































































































































































































































































































































































import { Vue } from 'vue-property-decorator';

export default class E2e extends Vue {

}
