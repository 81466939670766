




































import { Component, Vue } from 'vue-property-decorator';
import LineChart from '@/components/sandbox/LineChart';

@Component({
  components: {
    LineChart,
  },
})

export default class Charts extends Vue {
  chartData: object = {};

  /**
   * Component mounted.
   */
  mounted(): void {
    this.fillChart();
  }

  /**
   * Chart data generator.
   */
  fillChart(): void {
    this.chartData = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          label: 'Performance',
          data: [0, 10, 5, 15, 10, 20, 15, 25, 20, 30, 25, 40],
        },
      ],
    };
  }
}
