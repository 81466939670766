






























































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import LineChart from '@/components/sandbox/LineChart';
import { ChartColors } from '@/boot/charts';

@Component({
  components: {
    LineChart,
  },
})

export default class PageHeaders extends Vue {
  chartData: object = {};
  chartOptions: object = {};
  dataSetFirst: number[] = [0, 10, 5, 15, 10, 20, 15, 25, 20, 30, 25, 40];
  dataSetSecond: number[] = [7, 35, 12, 27, 34, 17, 19, 30, 28, 32, 24, 39];
  dataSetThird: number[] = [2, 12, 35, 25, 36, 25, 34, 16, 4, 14, 15, 37];

  /**
   * Component mounted.
   */
  mounted(): void {
    this.fillChart(this.dataSetFirst);
    this.configureChart();
  }

  /**
   * Chart data generator.
   */
  fillChart(dataSet: number[]): void {
    this.chartData = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          label: 'Performance',
          data: dataSet,
        },
      ],
    };
  }

  /**
   * Set chart options.
   */
  configureChart(): void {
    this.chartOptions = {
      scales: {
        yAxes: [
          {
            gridLines: {
              color: ChartColors.gray[900],
              zeroLineColor: ChartColors.gray[900],
            },
            ticks: {
              callback: (value: any): string | null => {
                if (!(value % 10)) {
                  return `€ ${value}k`;
                }

                return null;
              },
            },
          },
        ],
      },
    };
  }
}
