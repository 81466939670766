import { Prop } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';
import { Line, mixins as ChartMixins } from 'vue-chartjs';

@Component
export default class LineChart extends mixins(Line, ChartMixins.reactiveProp) {
  localOptions: object = {};

  @Prop({ default: {} })
  options!: object;

  /**
   * Component mounted.
   */
  mounted(): void {
    this.renderChart(this.getChartData(), Object.assign(this.options, this.localOptions));
  }

  /**
   * By using the reactiveProp mixin, we automatically
   * have a chartData property, that is observed for
   * changes.
   *
   * @see https://vue-chartjs.org/#/home?id=reactive-data
   */
  getChartData(): object {
    // @ts-ignore
    return this.chartData;
  }
}
